<template>
  <div v-if="priceVisible" class="flex">
    <ShopSharedPrice
      v-if="unitPrice"
      class="text-xs font-medium"
      :class="{
        'text-red-600': isListPrice,
        'text-gray-300': !isListPrice,
      }"
      :value="unitPrice"
      :quantity="quantity"
    />
    <ShopSharedPrice
      v-if="isListPrice"
      class="ml-2 text-xs font-medium text-gray-300 line-through"
      :value="price?.listPrice?.price"
      :quantity="quantity"
    />
  </div>
  <div v-else class="grow-0">
    <span
      class="block rounded p-1 text-[11px] font-medium leading-none"
      :class="[inverseColors ? 'bg-white' : 'bg-gray-10']"
    >
      {{ $t('product.professionalsOnly') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'

const props = withDefaults(
  defineProps<{
    product: Schemas['Product']
    quantity?: number
    inverseColors?: boolean
  }>(),
  {
    quantity: 1,
  }
)

const { product } = toRefs(props)

const { unitPrice, price, isListPrice } = useProductPrice(product)

const priceVisible = computed(() => {
  return product.value.extensions?.hiddenPrice?.isHiddenPrice !== true
})
</script>
